/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-destructuring */
<template>
  <div>
    <section id="card-images">
      <b-row>
        <b-col
          v-for="item in options"
          :key="item.id"
          md="5"
          lg="3"
          xl="2"
        >
          <a :href="item.id">
            <b-card
              :img-src="item.imagesLinks.mobile"
              border-variant=""
              class="mb-3"
            ><span
              id="streamTitle"
            ><span
               id="title"
               style="font-size: 16px; font-weight: bold;"
             >{{ item.name }}</span>
              <span
                id="stream"
                style=""
              >
                <strong>{{ item.channel }}</strong><br>
                <span
                  id="category"
                  style="font-size: 14px; font-weight: bold;"
                >
                  {{ new Date(item.date.datetime).toLocaleDateString("en-US", {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
  hour12: true}) }}<br>
                {{ new Date(item.date.datetime).toLocaleString("en-US", {hour: 'numeric', minute: 'numeric', hour12: true}) }}</span>
              </span></span>
              <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :href="item.strm"
          >
            Watch
          </b-button> -->
            </b-card>
          </a>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard,
} from 'bootstrap-vue'
import axios from 'axios'
import Vue from 'vue'

Vue.filter('capitalize', string => {
  const capitalFirst = string.toUpperCase()
  return capitalFirst
})
const prefix = 'https://edge.brimelive.com/live/'
const suffix = '/thumbnail.jpg'
export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      options: {},
      test: {},
      prefix,
      suffix,
      viewcount: {},
      mapped: null,
    }
  },
  async beforeCreate() {
    axios.get('https://spring-meadow-6420.brime.workers.dev/corsproxy/?apiurl=https://am.ticketmaster.com/twg2022/api/admin/v2/events?_format=json&epoch=upcoming&dataFilter=archticsName&_format=json&time=1625104980391')
      .then(response => {
        // handle success
        this.options = response.data
      })
      .then(() => {
        // always executed
      })
  },
}
</script>
<style scoped>
/* Scrollbar styles */
.dark-layout .card {
  box-shadow: unset;
 box-shadow: 0px 5px 10px 0 rgb(12 20 39 / 48%);
}
body {
  overflow: hidden; /* Hide scrollbars */
}
#style-4::-webkit-scrollbar-track
{
background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar
{
width: 10px;
background-color: #F5F5F5;
}
.card:hover {
  transform: translateY(-8px);
  box-shadow: 0px 0px 20px rgba(115,103,240,1);
}
.card:hover img {
  filter: saturate(1.2)
}

.card img {
  filter: saturate(1);
  transition: all 0.2s;
}
.live__pill {
  position: absolute;
  letter-spacing: 0.35px;
  left: 12px;
  top: 12px;
  padding: 2px 6px;
  background: rgba(255, 0, 0, 0.75);
  color: white;
  border-radius: 3px;
  font-size: 0.8em;
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.viewcount__pill {
  position: absolute;
  letter-spacing: 0.35px;
  right: 12px;
  top: 12px;
  padding: 2px 6px;
  background: rgba(0,0,0,0.75);
  background-filter: blur(10px);
  color: white;
  border-radius: 3px;
  font-size: 0.8em;
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
#style-4::-webkit-scrollbar-thumb
{
background-color: #000000;
border: 2px solid #555555;
}

.card {
  height: calc(100% - 3rem);
  display: flex;
  background-color: unset;
}

.card-body {
  padding: .75rem 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}
#category {
  margin-top: 2px;
  text-align: left;
  display: block;
}
#title {
  margin-left: 5px;
  color: #7a7c80;
  margin-top: auto;
  text-align: left;
  font-size: 15px;
    display: block;
  text-align: left;
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#streamtitle {
  font-size: 14px;
  line-height: 18px;
  vertical-align: center;
}
#stream {
  font-size: 12px;
  text-align: left;
  margin-bottom: 10px;
  height: 100%;
    display: block;
  text-align: left;
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#streamTitle {
  color: #7367f0;
  font-size: 14px;
  display: block;
  text-align: left;
  padding-top: 2px;
}
.badge-minimal{
  transform: translateY(11px);
}
</style>
